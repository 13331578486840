import React, { Component } from "react";
import { Col, FormGroup, Label, Input, Alert } from "reactstrap";
import InteractionSpeaker from "../../../components/Worker/InteractionSpeaker";
import SailBoat from "../../../static/images/people/SailBoat.png";
import "../RunWeek.css";
import PopupContent from '../../RunWeek/components/PopupContent';
import ConditionMessage from "./ConditionMessage";

class ConditionPanel extends Component {
  state = {
    response1: false,
    response2: false,
    response3: false,
    popupOpen: false,
    radioEnabled: false
  };

  componentWillUnmount() {
    this.setState({
      response1: false,
      response2: false,
      response3: false,
      popupOpen: false,
      radioEnabled: false
    });
  }

  onClick = event => {
    const choice = event.target.value; // string
    if (choice === "3") {
      this.setState({
        response1: false,
        response2: false,
        response3: true
      });
      this.props.updateResponse(3);
    } else if (choice === "2") {
      this.setState({
        response1: false,
        response2: true,
        response3: false
      });
      this.props.updateResponse(2);
    } else {
      this.setState({
        response1: true,
        response2: false,
        response3: false
      });
      this.props.updateResponse(1);
    }
  };

  togglePopup = () => {
    this.setState(prevState => ({
      popupOpen: !prevState.popupOpen,
      radioEnabled: true // Enable radio buttons on first popup open
    }));
  };

  handleAudioPlay = () => {
    this.setState({ radioEnabled: true }); // Enable radio buttons when audio is played
  };

  componentDidMount() {
    const { condition } = this.props.runWeek;
    // Enable radio buttons if the message does not end with '.mp3'
    if (!condition.condMessage.endsWith('.mp3')) {
      this.setState({ radioEnabled: true });
    }
  }

  render() {
    const { runWeek } = this.props;
    const { condition, conditionResponseList } = runWeek;
    const { popupOpen, radioEnabled } = this.state;

    let tempWorkerData = {};
    if (condition && condition.condTitle) {
      if (condition.condTitle === "Sailboat") {
        tempWorkerData = {
          name: "Farley Gnu"
        };
      } else if (condition.condTitle === "Team") {
        tempWorkerData = {
          name: "Team"
        };
      } else {
        tempWorkerData = {
          name: condition.condTitle
        };
      }
    } else {
      tempWorkerData = {
        name: "NOTFOUND"
      };
    }

    if (!conditionResponseList || conditionResponseList.Count < 1 || !condition) {
      return (
        <Alert key={9} color="error">
          For some reason, the data needed to display the next panel is not
          available. {condition.id} Please alert support@simulationpl.com
        </Alert>
      );
    }
    if (!condition) {
      return (
        <Alert key={9} color="error">
          For some reason, the data needed to display the next panel is not
          available. Please alert support@simulationpl.com
        </Alert>
      );
    }
    return (
      <div className="component-Alert">
        <div className="alert-Resource">
          <InteractionSpeaker workerData={tempWorkerData} />
        </div>
        <div className="alert-Body">
          <p>
            <strong>{condition.condAnnouncement}</strong>
          </p>
          <p>
            {condition.condTitle === "Sailboat" ? (
              <img alt="Sailboat" height="30%" width="80%" src={SailBoat} />
            ) : (
              <span />
            )}
          </p>
          <div className="alert-Message">
            {condition.condMessage.endsWith('.mp3') ? (
              <>
                <audio
                  controls
                  controlsList="nodownload"
                  src={require(`../../../static/audio/AudioMessage/${condition.condMessage}`)}
                  onPlay={this.handleAudioPlay}
                />
                <p>
                  <button
                    onClick={this.togglePopup}
                    style={{ background: 'none', border: 'none', padding: 0, color: '#5378ad', cursor: 'pointer' }}
                  >
                    Audio Transcript
                  </button>
                </p>
                {popupOpen && <PopupContent condition={condition} togglePopup={this.togglePopup} />}
              </>
            ) : (
              <ConditionMessage htmlContent={condition.condMessage} />
            )}
          </div>
          <div className="alert-Response">
            <FormGroup tag="fieldset" row>
              <legend className="col-form-label col-sm-6"></legend>
              <Col sm={10}>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={this.state.response1 === true}
                      type="radio"
                      name="response1"
                      value={1}
                      onChange={this.onClick}
                      disabled={!radioEnabled}
                    />
                    {conditionResponseList[0]}
                  </Label>
                </FormGroup>
                {conditionResponseList.length > 1 &&
                  conditionResponseList[1] &&
                  conditionResponseList[1] !== null &&
                  conditionResponseList[1] !== "NONE" &&
                  conditionResponseList[1] !== "" ? (
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={this.state.response2 === true}
                        type="radio"
                        name="response2"
                        value={2}
                        onChange={this.onClick}
                        disabled={!radioEnabled}
                      />
                      {conditionResponseList[1]}
                    </Label>
                  </FormGroup>
                ) : (
                  <span />
                )}
                {conditionResponseList.length > 2 &&
                  conditionResponseList[2] &&
                  conditionResponseList[2] !== null &&
                  conditionResponseList[2] !== "NONE" &&
                  conditionResponseList[2] !== "" ? (
                  <FormGroup check>
                    <Label check>
                      <Input
                        checked={this.state.response3 === true}
                        type="radio"
                        name="response3"
                        value={3}
                        onChange={this.onClick}
                        disabled={!radioEnabled}
                      />
                      {conditionResponseList[2]}
                    </Label>
                  </FormGroup>
                ) : (
                  <span />
                )}
              </Col>
            </FormGroup>
          </div>
          <div
            style={{
              textAlign: "right",
              marginRight: "10px",
              color: "grey"
            }}
          >
            <small>{condition.conditionId} </small>
          </div>
        </div>
      </div>
    );
  }
}

export default ConditionPanel;
