import React from "react";
import StyledDiv from "./StyledDiv";

const DefinitionOfDone = () => (
  <StyledDiv>
    <h3>Definition of Done</h3>
    <p>
      The product team needs to have a common understanding of what “Done”
      means. The Definition of Done applies to every increment that is
      releasable. Here is a starting Definition of Done for the team:
    </p>
    <ul>
      <li>Acceptance criteria are met</li>
      <li>Functional tests passed</li>
      <li>No major defects are open</li>
      <li>Code has been peer-reviewed</li>
      <li>Documentation is complete </li>
      <li>User acceptance complete</li>
      <li>All code and configuration is checked into GitHub</li>
    </ul>
    <h5>Are We There Yet?</h5>
    <p>Answering this question is tricky when there’s no shared agreement on what <b>"there" </b>actually means. 
      In an Agile world filled with cloud-based solutions, completion isn’t marked by a shrink-wrapped box of finished products. 
      Instead, software and features are continuously evolving, with opportunities to ship code that may never feel truly <b>"done."</b></p>
      <p>That’s why defining a clear <b>"Definition of Done"</b> is crucial. 
        It sets a shared understanding of when projects, initiatives, and features are genuinely complete helping teams align expectations and move forward with confidence.</p>
  </StyledDiv>
);

export default DefinitionOfDone;
