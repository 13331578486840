import React, { useState, useEffect } from "react";
import { Card, CardBody, Progress, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import CardHeader from "../../../components/styled/CardHeader";
import { initialCap } from "../../../utilities/utils";

const ProgressCard = props => {
  const [progress, setProgress] = useState("0");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (props.currentWeekDay === "MONDAY") {
      if (props.runWeekStatus.returnStatus !== "OFF") {
        setProgress("10");
      } else {
        setProgress("0");
      }
    }
    if (props.currentWeekDay === "TUESDAY") {
      setProgress("30");
    }
    if (props.currentWeekDay === "WEDNESDAY") {
      setProgress("50");
    }
    if (props.currentWeekDay === "THURSDAY") {
      setProgress("70");
    }
    if (props.currentWeekDay === "FRIDAY") {
      setProgress("90");
    }
    if (props.runWeekStatus.returnStatus === "EOW") {
      setProgress("100");
    }
  }, [props.runWeekStatus.returnStatus, props.currentWeekDay]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Card>
      <CardHeader>
        <b>Overall Progress: Today is {initialCap(props.currentWeekDay)}</b>
        <Button type="button" color="secondary" size="sm"
          className="float-right" onClick={toggleModal}>
          <i className="far fa-file" /> Info
        </Button>
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Run Sprint Info</ModalHeader>
          <ModalBody>
            <p>
              When ready, click on the &quot;Run Sprint&quot; button. This will
              execute the sprint.
            </p>
            <p>
              When responding to the pop-up communications in the Communications
              window, consider the anticipated results of each response before
              selecting your desired response.
            </p>
            <p>
              At the end of the week you will be automatically redirected to the
              Reports module; carefully look over all the reports. The reports are
              explained in detail in the Help Documentation.
            </p>
            <p>
              <em>
                If at any time your sprint execution gets interrupted, just come
                back to the page and click the Run Sprint button. Your work will be
                saved and you can pick up where you left off before the
                interruption.
              </em>
            </p>
          </ModalBody>
        </Modal>
      </CardHeader>
      <CardBody>
        <Progress value={progress} />
      </CardBody>
    </Card>
  );
};

export default ProgressCard;
