/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { NavbarToggler, NavbarBrand, Nav, NavItem, Button } from "reactstrap";
import styled from "styled-components";
import OrientationModal from "components/Overview/OrientationModal";
import { UncontrolledTooltip } from "./styled/UncontrolledTooltip";
import auth from "../Auth";
import TeamModal from "./TeamModal";
import HelpModal from "./help/HelpModal";
import ProjectReferenceModal from "./reference/ProjectReferenceModal";
import ChatModal from "./Chat/ChatModal";
import { ProjectContext } from "../GlobalState";
import { store } from "react-notifications-component";

const HeaderTooltip = styled(UncontrolledTooltip)`
  .tooltip-inner {
    background: #5378ad;
    color: white;
    font-weight: bold;
    padding: 3px;
    border-radius: 3px;
    margin-top: -10px;
  }
`;

const ChatHightlight = styled.i`
@keyframes example {
  5%  {color: maroon}
  10% {color: maroon}
  15% {color: maroon}
  20% {color: maroon}
  25% {color: maroon}
  30% {color: maroon}
  35% {color: maroon}
  40% {color: maroon}
  45% {color: maroon}
  50% {color: maroon}
  55% {color: maroon}
  60% {color: maroon}
  65% {color: maroon}
  70% {color: maroon}
  75% {color: maroon}
  80% {color: maroon}
  85% {color: maroon}
  90% {color: maroon}
  95% {color: maroon}
  100% {color: maroon}
}
`;

const AppHeader = props => {
  const [state] = useContext(ProjectContext);
  const [whichModal, setWhichModal] = useState("");

  const sidebarToggle = e => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
  };

  const sidebarMinimize = e => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-minimized");
  };

  const mobileSidebarToggle = e => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
  };

  const asideToggle = e => {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  };

  return (
    <header className="app-header navbar">
      <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
        &#9776;
      </NavbarToggler>
      <NavbarBrand />
      <NavbarToggler className="d-md-down-none mr-auto" onClick={sidebarToggle}>
        &#9776;
      </NavbarToggler>
      {state.autoRun === true && (
        <p style={{ color: "red" }}>
          Auto run: ON <br />
          Random responses: {state.autoRunRandom.toString().toUpperCase()}
        </p>
      )}

      <Nav className="ml-auto mr-3" navbar>
        <NavItem>
          <HeaderTooltip id="emailtt" placement="bottom" target="emailLink">
            Chat
          </HeaderTooltip>
          {state.highlightChat ?
            <Button
              id="emailLink"
              color="link"
              onClick={() => setWhichModal("chatModal")}
            >
              <ChatHightlight style={{
                animationName: 'example',
                animationDuration: '10s',
              }} className="fa fa-comment-alt" />
            </Button>
            :
            <span>
              <Button
                id="emailLink"
                color="link"
                onClick={() => setWhichModal("chatModal")}
              >
                <i className="fa fa-comment-alt" />
              </Button>
            </span>
          }
        </NavItem>
        <NavItem>
          <HeaderTooltip placement="bottom" target="helpLink">
            Help
          </HeaderTooltip>
          <Button
            id="helpLink"
            color="link"
            onClick={() => setWhichModal("helpModal")}
          >
            <i className="fa fa-life-ring" />
          </Button>
        </NavItem>
        <NavItem>
          <HeaderTooltip placement="bottom" target="orientationLink">
            Overview{" "}
          </HeaderTooltip>
          <Button
            id="orientationLink"
            color="link"
            onClick={() => setWhichModal("orientationModal")}
          >
            <i className="fa fa-file-alt"></i>
          </Button>
        </NavItem>
        <NavItem>
          <HeaderTooltip placement="bottom" target="teamLink">
            Team{" "}
          </HeaderTooltip>
          <Button
            id="teamLink"
            color="link"
            onClick={() => setWhichModal("teamModal")}
          >
            <i className="fa fa-users" />
          </Button>
        </NavItem>
        <NavItem>
          <span>
            {state.teamStudentFirst} {state.teamStudentLast}{" "}
            {state.currentTeamId != null && `- Team ${state.currentTeamId}`}
          </span>
        </NavItem>
        <NavItem className="d-md-down-none">
          <Button
            color="link"
            onClick={async () => {
              auth.logout();
            }}
          >
            <i className="fa fa-lock" /> Logout
          </Button>
        </NavItem>
      </Nav>
      {whichModal === "orientationModal" && (
        <OrientationModal
          teamId={state.currentTeamId}
          toggleModal={() => setWhichModal("")}
        />
      )}
      {whichModal === "helpModal" && (
        <HelpModal toggleModal={() => setWhichModal("")} />
      )}
      {whichModal === "projectReferenceModal" && (
        <ProjectReferenceModal toggleModal={() => setWhichModal("")} />
      )}
      {state.hasActiveTeam && whichModal === "chatModal" && (
        <ChatModal
          toggleModal={() => setWhichModal("")}
          currentUser={props.currentUser}
        />
      )}
      {whichModal === "teamModal" && (
        <TeamModal
          teamId={state.currentTeamId}
          toggleModal={() => setWhichModal("")}
        />
      )}
      {/* {unReadMessages.length > 0 ? <Notification unReadMessages={unReadMessages} /> : ""} */}
    </header>
  );
};

export default AppHeader;
